














































































































































import Vue from 'vue'
import {
  createTax,
  createTaxProductVariant,
  deleteTax,
  deleteTaxProductVariant,
  getTax,
  getTaxCurrencies,
  syncTaxCurrencies,
  updateTax,
  updateTaxCurrencies,
} from '@/lib/tax'
import {
  CreateTaxInput,
  CreateTaxProductVariantInput,
  CurrencyEnum,
  DeleteTaxProductVariantInput,
  Maybe,
  Price,
  Tax,
  TaxCollectionEnum,
  TaxCurrenciesInput,
  TaxProductVariant,
  TaxTypeEnum,
} from '../../../../../shared/types/types'
import NavigateBack from '@/components/shared/NavigateBack.vue'
import AddProductVariantsModal from '@/components/products/AddProductVariantsModal.vue'
import { getProduct, getVariant } from '@/lib/product'
import ConfirmModal from '@/components/shared/ConfirmModal.vue'
import CurrenciesModal from '@/components/shared/CurrenciesModal.vue'
import { ProductVariantKey } from '@/types'
import CurrencyInput from '@/components/shared/CurrencyInput.vue'

export default Vue.extend({
  name: 'Tax',
  components: { NavigateBack, CurrencyInput },
  data() {
    return {
      isCreateTaxPage: this.$route.params.taxId === 'new',
      tax: {} as Maybe<Tax> | CreateTaxInput,
      taxAmount: 0,
      originalProductVariants: [] as TaxProductVariant[],
      productVariantsToAdd: [] as CreateTaxProductVariantInput[],
      productVariantsToRemove: [] as Omit<
        DeleteTaxProductVariantInput,
        'taxId'
      >[],
      isLoading: false,
      TaxTypeEnum,
      TaxCollectionEnum,
      currencies: [] as Price[],
      isSyncing: false,
    }
  },
  computed: {
    /**
     * Page title
     */
    title(): string {
      if (this.isCreateTaxPage) {
        return 'Create Tax'
      }

      return this.tax?.title || ''
    },
    /**
     * Check whether the collection type is "selected products"
     */
    isSelectedProductsCollectionType(): boolean {
      return (
        this.tax?.collectionType === TaxCollectionEnum.SelectedProductVariants
      )
    },
    /**
     * Get text for selected products button
     */
    getSelectedProductsButtonText(): string {
      return (this.tax?.productVariants || []).length > 0
        ? 'Edit Products'
        : 'Add Products'
    },
    computedTaxAmount: {
      get(): number {
        return this.taxAmount
      },
      set(value: string) {
        if (this.tax?.type === TaxTypeEnum.FlatRate && this.tax.flatRate) {
          this.tax.flatRate.amount = Number(value)
        }

        if (this.tax?.type === TaxTypeEnum.Percentage) {
          this.tax.percentage = Number(value)
        }

        this.taxAmount = Number(value)
      },
    },
    hasTaxVariants(): boolean {
      return !!this.tax?.productVariants?.length
    },
    isFlatRateTax(): boolean {
      return this.tax?.type === TaxTypeEnum.FlatRate
    },
  },
  async created() {
    if (this.isCreateTaxPage && this.tax) {
      this.tax = {
        title: '',
        collectionType: TaxCollectionEnum.AllProducts,
        type: TaxTypeEnum.FlatRate,
        percentage: 0,
        flatRate: {
          amount: 0,
          currency: CurrencyEnum.Usd,
        },
        currency: CurrencyEnum.Usd,
        productVariants: [],
      }
    } else {
      this.tax = await getTax(this.$route.params.taxId)
      const data = await getTaxCurrencies(this.$route.params.taxId)
      this.currencies = data.currencies
      this.setTaxAmount()

      this.originalProductVariants = this.tax?.productVariants || []
    }
  },
  methods: {
    /**
     * Get active classes for tax type icon button
     *
     * @param type - TaxTypeEnum
     */
    getActiveTaxTypeClasses(type: TaxTypeEnum) {
      if (this.tax?.type === type) {
        return {
          'bg-primary': true,
          'text-white': true,
        }
      }
    },
    /**
     * Set tax type
     *
     * @param type - TaxTypeEnum
     */
    setTaxValueType(type: TaxTypeEnum) {
      if (!this.tax) return

      this.tax.type = type

      //if (this.isFlatRateTax && this.tax.flatRate) {
      //  this.tax.flatRate.amount = this.taxAmount
      //}

      //if (this.isPercentageTax(this.tax)) {
      //  this.tax.percentage = this.taxAmount
      //}
    },
    /**
     * Check whether the given tax amount is a percentage
     *
     * @param tax - the tax object
     */
    isPercentageTax(tax: Tax | CreateTaxInput) {
      return tax.type === TaxTypeEnum.Percentage
    },
    /**
     * Set our internal "taxAmount" value that persists between tax types
     */
    setTaxAmount() {
      if (!this.tax) return

      if (this.isFlatRateTax && this.tax.flatRate) {
        this.taxAmount = Number(this.tax.flatRate.amount) / 100 || 0
      }

      if (this.isPercentageTax(this.tax)) {
        this.taxAmount = this.tax.percentage || 0
      }
    },
    /**
     * Check whether the given product variant has been newly-added
     *
     * @param productVariant - a product variant object
     */
    isNewProductVariant(productVariant: ProductVariantKey) {
      return (
        !this.originalProductVariants.some(
          (existingProductVariant) =>
            existingProductVariant.productId === productVariant.productId &&
            existingProductVariant.variantId === productVariant.variantId
        ) &&
        !this.productVariantsToAdd.find(
          (newProductVariant) =>
            newProductVariant.productId === productVariant.productId &&
            newProductVariant.variantId === productVariant.variantId
        )
      )
    },
    /**
     * Check whether the given product variant was orignally
     * returned from the API
     *
     * @param productVariant - ProductVariantKey
     */
    isOriginalProductVariant(productVariant: ProductVariantKey) {
      return this.originalProductVariants.find(
        (originalProductVariant) =>
          originalProductVariant.productId === productVariant.productId &&
          originalProductVariant.variantId === productVariant.variantId
      )
    },
    /**
     * Check whether an original product variant (returned from the API request)
     * has been removed
     *
     * @param productVariant - the product variant object
     */
    hasOriginalProductVariantBeenRemoved(productVariant: ProductVariantKey) {
      if (!this.tax) return

      return (
        this.isOriginalProductVariant(productVariant) &&
        !((this.tax.productVariants as TaxProductVariant[]) || []).find(
          (taxProductVariant) =>
            taxProductVariant.productId === productVariant.productId &&
            taxProductVariant.variantId === productVariant.variantId
        )
      )
    },
    /**
     * Open the "AddProductVariants" modal
     */
    openAddProductVariantsModal() {
      if (!this.tax) return

      this.$buefy.modal.open({
        parent: this,
        component: AddProductVariantsModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          existingProductVariants: (this.tax
            .productVariants as TaxProductVariant[]).map((productVariant) => ({
            productId: productVariant.productId,
            variantId: productVariant.variantId,
          })),
        },
        events: {
          save: async (
            selectedProductVariants: ProductVariantKey[],
            closeModal: () => void
          ) => {
            await this.handleProductVariantChanges(selectedProductVariants)

            closeModal()
          },
        },
      })
    },
    /**
     * Given the selected product variants, determine which variant
     * has been added & removed
     *
     * @param selectedProductVariants - selected product variants
     */
    async handleProductVariantChanges(
      selectedProductVariants: ProductVariantKey[]
    ) {
      if (!this.tax) return

      // The type is of TaxProductVariant, but these will get added to this.tax.productVariants
      // to be shown in the UI but which in turn will complain as the types don't match.

      const newProductVariants: any[] = []

      for (const productVariant of selectedProductVariants) {
        console.log('🚀 ~ file: Tax.vue:408 ~ productVariant:', productVariant)
        if (this.isNewProductVariant(productVariant)) {
          const [product, variant] = await Promise.all([
            getProduct(productVariant.productId),
            getVariant(productVariant.productId, productVariant.variantId),
          ])

          newProductVariants.push({
            ...productVariant,
            product: {
              title: product?.title,
              variants: [],
            },
            variant: {
              imageUrl: variant?.imageUrl,
              title: variant?.title,
            },
          })

          this.productVariantsToAdd.push({
            ...productVariant,
            isActive: true,
          })
        }
      }

      // Remove any unselected product variants
      const unselectedProductVariants = (this.tax
        .productVariants as TaxProductVariant[]).filter(
        (taxProductVariant) =>
          !selectedProductVariants.find(
            (selectedProductVariant) =>
              selectedProductVariant.productId ===
                taxProductVariant.productId &&
              selectedProductVariant.variantId === taxProductVariant.variantId
          )
      )

      unselectedProductVariants.forEach((productVariant) =>
        this.removeProductVariant(
          productVariant.productId,
          productVariant.variantId
        )
      )

      this.tax = {
        ...this.tax,
        productVariants: [
          ...(this.tax.productVariants || []),
          ...newProductVariants,
        ],
      }
    },
    /**
     * Remove product variant from collection tax
     *
     * @param productId - the product ID
     * @param variantId - the variant ID
     */
    removeProductVariant(productId: string, variantId: string) {
      if (!this.tax) return

      // If we removed a newly added product variant, make sure to
      // remove it from this.productVariantsToAdd
      this.productVariantsToAdd = this.productVariantsToAdd.filter(
        (productVariant) => productVariant.variantId !== variantId
      )

      this.tax.productVariants = (this.tax
        .productVariants as TaxProductVariant[]).filter(
        (productVariant) => productVariant.variantId !== variantId
      )

      if (
        this.hasOriginalProductVariantBeenRemoved({
          productId,
          variantId,
        })
      ) {
        this.productVariantsToRemove.push({
          productId,
          variantId,
        })
      }
    },
    /**
     * Open confirmation modal to delete tax
     */
    openDeleteTaxModal() {
      this.$buefy.modal.open({
        parent: this,
        component: ConfirmModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          title: 'Delete Tax?',
          body:
            'Are you sure you want to delete this tax? This action cannot be undone.',
          icon: 'alert-circle',
          type: 'is-danger',
          cancelButtonText: 'No, keep tax',
          confirmButtonText: 'Yes, delete tax',
          confirmButtonAction: async (closeModal: () => void) => {
            await deleteTax(this.$route.params.taxId)

            closeModal()

            this.$router.push('/settings/taxes')
          },
        },
      })
    },
    /**
     * Handle save
     */
    async handleSave() {
      try {
        this.isLoading = true

        if (this.isCreateTaxPage) {
          await this.createTax()
        } else {
          await this.updateTax()
        }
      } catch (error) {
        console.error(error)

        this.isLoading = false

        this.$buefy.toast.open({
          message: 'Something went wrong!',
          type: 'is-danger',
          position: 'is-bottom',
        })
      }
    },
    async createTax() {
      if (!this.tax) return

      let taxId

      if (this.tax.type === TaxTypeEnum.FlatRate && this.tax.flatRate) {
        this.tax.percentage = undefined

        this.tax.flatRate.amount = Number(this.tax.flatRate.amount) * 100
      }

      if (this.tax.type === TaxTypeEnum.Percentage) {
        this.tax.flatRate = undefined
      }

      if (this.tax.collectionType === TaxCollectionEnum.AllProducts) {
        const tax = await createTax(this.tax as CreateTaxInput)

        taxId = tax?.taxId
      }

      if (
        this.tax.collectionType === TaxCollectionEnum.SelectedProductVariants
      ) {
        const productVariantsInput = (this.tax
          .productVariants as TaxProductVariant[]).map((productVariant) => ({
          productId: productVariant.productId,
          variantId: productVariant.variantId,
          isActive: true,
        }))

        const tax = await createTax({
          ...this.tax,
          productVariants: productVariantsInput,
        } as CreateTaxInput)

        taxId = tax?.taxId
      }

      this.isLoading = false

      this.$buefy.toast.open({
        message: 'Tax created!',
        type: 'is-success',
        position: 'is-bottom',
      })

      this.$router.push(`/settings/taxes/${taxId}`)
    },
    /**
     * Update tax
     */
    async updateTax() {
      if (!this.tax) return

      const isFlatRate = this.tax.type === TaxTypeEnum.FlatRate
      const isPercentage = this.tax.type === TaxTypeEnum.Percentage

      const updateTaxParams = {
        taxId: this.$route.params.taxId,
        title: this.tax.title,
        currency: CurrencyEnum.Usd,
        flatRate: isFlatRate
          ? {
              amount: this.tax.flatRate!.amount || 0,
              currency: CurrencyEnum.Usd,
            }
          : undefined,
        percentage: isPercentage ? this.tax.percentage?.toString() : undefined,
        type: this.tax.type,
        collectionType: this.tax.collectionType,
        updatedAt: new Date().toISOString(),
      }

      if (this.tax.collectionType === TaxCollectionEnum.AllProducts) {
        await updateTax(updateTaxParams)
      }

      if (
        this.tax.collectionType === TaxCollectionEnum.SelectedProductVariants
      ) {
        await Promise.all([
          updateTax(updateTaxParams),
          this.productVariantsToAdd.map((productVariant) =>
            createTaxProductVariant({
              taxId: this.$route.params.taxId,
              productId: productVariant.productId,
              variantId: productVariant.variantId,
            })
          ),
          this.productVariantsToRemove.map((productVariant) =>
            deleteTaxProductVariant({
              taxId: this.$route.params.taxId,
              productId: productVariant.productId,
              variantId: productVariant.variantId,
            })
          ),
        ])
      }

      this.isLoading = false

      this.$buefy.toast.open({
        message: 'Tax updated!',
        type: 'is-updated',
        position: 'is-bottom',
      })
    },
    openCurrenciesModal() {
      this.$buefy.modal.open({
        parent: this,
        component: CurrenciesModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          currencies: this.currencies,
          async save(currencies: TaxCurrenciesInput[]) {
            await updateTaxCurrencies(this.$route.params.taxId, currencies)
          },
        },
      })
    },
    async handleSync() {
      try {
        this.isSyncing = true

        await syncTaxCurrencies(this.$route.params.taxId)

        this.$buefy.toast.open({
          message: 'Currencies updated',
          type: 'is-success',
          position: 'is-bottom',
        })
      } catch (error) {
        console.error(error)

        this.$buefy.toast.open({
          message: 'Something went wrong!',
          type: 'is-danger',
          position: 'is-bottom',
        })
      } finally {
        this.isSyncing = false
      }
    },
  },
})
