
































import Vue from 'vue'
import ModalHeader from '@/components/shared/ModalHeader.vue'

export default Vue.extend({
  name: 'ConfirmModal',
  components: {
    ModalHeader,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'is-success',
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
    confirmButtonText: {
      type: String,
      default: 'Confirm',
    },
    confirmButtonAction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    handleConfirm() {
      this.isLoading = true

      this.confirmButtonAction(() => this.$emit('close'))
    },
  },
})
