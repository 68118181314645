










































import Vue, { PropType } from 'vue'
import mixins from 'vue-typed-mixins'
import debounce from 'just-debounce-it'
import { getElasticSearchClient } from '@/services/elasticSearch'
import ProductsTableMixin from '@/mixins/ProductsTable.mixin'
import { ProductVariantKey } from '@/types'

enum ProductType {
  Physical = 'physical',
  Virtual = 'virtual',
}

interface Row {
  productId: string
  title: string
  variants: {
    productId: string
    variantId: string
    title: string
    trackQuantity: boolean
    variantType: string
    quantity: number
  }[]
  createdAt: Date
  updatedAt: Date
}

export default mixins(ProductsTableMixin).extend({
  name: 'AddProductVariantsModal',
  props: {
    existingProductVariants: {
      type: Array as PropType<ProductVariantKey[]>,
      required: true,
    },
  },
  data() {
    return {
      checkedRows: [] as Row[],
      currentPage: 1,
      isLoading: false,
      itemsPerPage: 25,
      search: '',
      searchInput: '',
      searchResult: [] as Record<string, any>,
      selectedProductIds: [] as string[],
      selectedProductVariants: [] as ProductVariantKey[],
    }
  },
  computed: {
    query(): Record<string, any> {
      const query: Record<string, any> = {
        query: {
          bool: {
            filter: [],
            must: [],
          },
        },
        track_total_hits: true,
        sort: this.sort,
        size: this.itemsPerPage,
        from: (this.currentPage - 1) * this.itemsPerPage,
      }

      if (this.search && this.search.length > 0) {
        query.query.bool.filter.push({
          match_phrase: { title: this.search },
        })
      }

      if (
        query.query.bool.filter.length === 0 &&
        query.query.bool.must.length === 0
      ) {
        query.query = {
          match_all: {},
        }
      }

      return query
    },
    nbHits(): number {
      return this.searchResult && this.searchResult.hits
        ? this.searchResult.hits.total.value
        : 0
    },
    rows(): Row[] {
      if (!this.searchResult || this.searchResult.length === 0) {
        return []
      }
      return this.searchResult.hits.hits.map((h: { _source: Row }) => h._source)
    },
    checkedProductIds(): string[] {
      return this.checkedRows.map((row) => row.productId)
    },
  },
  watch: {
    searchInput: {
      immediate: true,
      handler: debounce(function () {
        //@ts-ignore
        this.search = this.searchInput.trim()
      }, 500),
    },
    query: {
      immediate: true,
      deep: true,
      handler() {
        this.fetchData()
      },
    },
    rows(rows: Row[]) {
      for (const productVariant of this.existingProductVariants) {
        const product = rows.find(
          (row) => row.productId === productVariant.productId
        )

        this.selectedProductVariants.push(
          this.generateProductVariantKey(
            productVariant.productId,
            productVariant.variantId
          )
        )
        this.addOrRemoveProductId(product as Row)
      }
    },
  },
  methods: {
    debouncedSearchInput: debounce(function (this: any, value: string) {
      this.search = value
    }, 500),
    async fetchData(): Promise<void> {
      const elasticSearch = getElasticSearchClient()
      const { data } = await elasticSearch.post(
        '/inventory_products/_search',
        this.query
      )
      this.searchResult = data
    },
    getVariantText(data: Record<string, any>) {
      const { variants } = data
      if (variants && variants.length > 0) {
        return `for ${variants.length} ${
          variants.length > 1 ? 'variants' : 'variant'
        }`
      } else {
        return ''
      }
    },
    // TODO: types
    setCheckedRows(checkedRows: Row[]) {
      this.checkedRows = checkedRows
    },
    // TODO: types
    isRowChecked(a: any, b: any) {
      return a.productId === b.productId
    },
    // TODO: types
    getTotalVariantsQuantity(variants: any[]) {
      return variants.reduce((total, variant) => {
        if (variant.trackQuantity) {
          return (total += variant.quantity)
        }

        return total
      }, 0)
    },
    isQuantityZero(product: any) {
      return this.getTotalVariantsQuantity(product.variants) === 0
    },
    /**
     * Set loading state
     *
     * @param isLoading - current loading state
     */
    setLoading(isLoading: boolean) {
      this.isLoading = isLoading
    },
    /**
     * Get all selected variants that correspond to the the given productId
     *
     * @param productId - a product id
     */
    getSelectedVariantsForProduct(productId: string) {
      return this.selectedProductVariants.filter(
        (productVariant) => productVariant.productId === productId
      )
    },
    /**
     * Selected or de-select all product variants for a given product
     *
     * @param product - the product object
     */
    addOrRemoveAllProductVariants(product: Row) {
      const selectedVariantsOfProducts = this.getSelectedVariantsForProduct(
        product.productId
      )

      if (selectedVariantsOfProducts.length === product.variants.length) {
        this.selectedProductVariants = this.selectedProductVariants.filter(
          (productVariant) => productVariant.productId !== product.productId
        )
      } else {
        product.variants.forEach((variant) => {
          if (
            !this.selectedProductVariants.find(
              (productVariant) => productVariant.variantId === variant.variantId
            )
          ) {
            this.selectedProductVariants.push(
              this.generateProductVariantKey(
                product.productId,
                variant.variantId
              )
            )
          }
        })
      }
    },
    /**
     * Check whether the product checkbox should show "indeterminate" state
     *
     * @param product - Row
     */
    isIndeterminate(product: Row) {
      const selectedVariantsOfProducts = this.getSelectedVariantsForProduct(
        product.productId
      )

      return !!(
        selectedVariantsOfProducts.length &&
        selectedVariantsOfProducts.length !== product.variants.length
      )
    },
    /**
     * Add or remove a productId from this.selectedProductIds
     * Used internally to allow correct indeterminate select behaviour
     *
     * @param product - Row
     */
    addOrRemoveProductId(product: Row) {
      const selectedVariantsOfProducts = this.getSelectedVariantsForProduct(
        product.productId
      )

      if (selectedVariantsOfProducts.length === product.variants.length) {
        this.selectedProductIds.push(product.productId)
      } else {
        this.selectedProductIds = this.selectedProductIds.filter(
          (id) => id !== product.productId
        )
      }
    },
    /**
     * Generate a ProductVariantKey
     *
     * @param productId - a product ID
     * @param variantId - a variant ID
     */
    generateProductVariantKey(productId: string, variantId: string) {
      return {
        productId,
        variantId,
      }
    },
    /**
     * Close the modal
     */
    close() {
      this.$emit('close')
    },
    /**
     * Method to handle save button
     *
     * Emits an event with selected product variants & close method
     */
    async handleSave() {
      this.setLoading(true)
      this.$emit('save', this.selectedProductVariants, this.close)
    },
  },
})
